.platformWrapper .platformItm .gameIcon{
	width: 102px;
}

#nav{
	.menuNav > li{
		.menuArrow{
			background-position: 100% 85%;
		}

		&:hover{
			ul {
                display: block!important;
            }
		}
	}
}

i{
	&.refresh-img{
		display:none;
	}
}

